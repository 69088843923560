.container {
  display: flex;
  font-size: 13px;
  flex-direction: column;
  margin-top: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.header {
  font-size: 19px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.subheader {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.divider {
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}

.center {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.title {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.jobtitle {
  display: flex;
  flex-direction: row;
}

.job {
  text-decoration: underline;
  width: 50%;
  justify-content: flex-start;
  display: flex;
}

.jobdate {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}

.jobsubtitle {
  justify-content: flex-start;
  display: flex;
  color: gray;
  padding-left: 10px;
  margin-bottom: 5px;
  font-style: italic;
}

.detail {
  justify-content: flex-start;
  display: list-item;
  padding-left: 5px;
}

.pdf {
  display: flex;
  text-decoration: none;
  font-size: 13px;
  justify-content: center;
  align-items: center;
}

.pdf-image {
  position: relative;
  top: 2px;
  width: 18px;
  height: 18px;
}

