.container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.collapsed {
  display: none !important;
}

.year {
  height: auto;
  flex-direction: column;
  border-right: 1px solid lightgray;
  display: flex;
  position: relative;
  cursor: pointer;
  width: 100%;
  span {
    margin-left: 20px;
  }
  border-bottom: 1px solid lightgray;
}

.month {
  height: auto;
  flex-direction: column;
  display: flex;
  position: relative;
  cursor: pointer;
  width: 100%;
  span {
    margin-left: 40px;
  }
}

.post {
  height: 18px;
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  span {
    margin-left: 60px;
  }
}

.active {
  font-weight: 600;
}

.postName {
  overflow: hidden;
}

.popup {
  font-size: 10px;
  display: flex;
  overflow: hidden;
  max-height: 100px;
  max-width: 100px;
}
