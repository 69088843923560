.container {
  display: flex;
  width: 100%;
  margin-top: auto;
  align-items: center;
  text-align: center;
  height: 70px;
  border-top: 1px solid lightgray;
  justify-content: flex-end;
  padding: 10px 0;
}

