:global(.center) {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
}

:global(.tags) {
  color:gray;
  font-style: italic;
  font-size: 12px;
  margin-bottom: 20px;
}

:global(.date) {
  color:gray;
  font-style: italic;
  font-size: 12px;
}

:global(.topic) {
  cursor:pointer;
  text-decoration: underline;
  color:gray;
}

:global(.gray) {
  color: gray;
}

:global(.margin-bottom) {
  margin-bottom: 10px;
}
