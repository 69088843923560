.container {
  margin-top: 20px;
  height: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid lightgray;
  //border-radius: 2px;
  display: flex;
}

.table {
  border-right: 1px solid lightgray;
  width: 20%;
  display: flex;
  flex-direction: column;

  a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
    color: black;
  }

  a:hover {
    color: gray;
  }
}

.year {
  display: flex;
  font-size: 14px;
}