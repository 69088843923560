.container {
  display: flex;
  font-size: 13px;
  flex-direction: column;
  margin-top: 20px;
  height: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid lightgray;
}

.header {
  font-size: 19px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.subheader {
  font-size: 13px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.divider {
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}

.center {
  display: flex;
  flex-direction: column;
  place-items: center;
}
