.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid lightgray;
}

.header {
  font-size: 36px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.subheader {
  font-size: 18px;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  place-items: center;
}

.divider {
  border-bottom: 1px solid lightgray;
  margin-top: 10px;
  margin-bottom: 10px;
}

.center {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.title {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.jobtitle {
  display: flex;
  flex-direction: row;
}

.job {
  text-decoration: underline;
  width: 50%;
  justify-content: flex-start;
  display: flex;
}

.jobdate {
  width: 50%;
  justify-content: flex-end;
  display: flex;
  text-decoration: underline;
}

.jobsubtitle {
  justify-content: flex-start;
  display: flex;
  color: gray;
  padding-left: 10px;
  margin-bottom: 5px;
  font-style: italic;
}

.detail {
  justify-content: flex-start;
  display: list-item;
  padding-left: 5px;
}
