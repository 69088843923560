.container {
  padding-top: 20px;
  width: 80%;
  display: flex;
}

.table {
  min-width: 180px;
  display: flex;
  flex-direction: column;

  a {
    width: 118px;
    display: inline-block;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 12px;
    text-decoration: none;
    color: black;
    height: 19px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  a:hover {
    color: gray;
  }
}

.year {
  display: flex;
  font-size: 14px;
}
