.container {
  background-color: rgb(240, 240, 240);
  display: flex;
  align-items: center;
  height: 70px;
  border-radius: 2px;
}

.avatar {
  position: relative;
  left: 2px;
  top: 2px;

  img {
    border: 1px solid white;
    width: 64px;
    height: 64px;
  }
}

.items {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  user-select: none;
}

.icons {
  display: flex;
  width: 100%;
  height: inherit;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  i {
    font-size: 18px !important;
    margin: 0 5px !important;
    cursor: pointer;
  }
  i:hover {
    color: rgb(35, 101, 166) !important;
  }
}
