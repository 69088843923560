.container {
  margin-left: 16px;
  padding-bottom: 2px;
  padding-left: 20px;

  a {
    cursor: pointer;
    text-decoration: none;
    color: rgb(65, 131, 196);;
  }

  a:hover {
    color: rgb(35, 101, 166);
  }
}

.active {
  font-weight: 600;
}

//.container:hover {
//  border-bottom: 2px solid white;
//  border-radius: 2px;
//}
