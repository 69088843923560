.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

