.container {
  display: flex;
  font-size: 13px;
  flex-direction: column;
  margin-top: 20px;
  height: 80%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid lightgray;
}

.header {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
}
